<template>
  <div id="app">
    <div class="cashel">
      <div>
        <img
          srcset="./assets/logo-sm.png 400w, ./assets/logo-lg.png 900w"
          sizes="(max-width: 1180px) 400px, 900px"
          src="./assets/logo-sm.png"
          alt="Cashel Stag Logo"
        />
      </div>
      <div class="address">
        40 WOODLANDS ROAD<br />
        GLASGOW
      </div>
      <div class="opening">
        OPENING TIMES<br />
        MON TO FRI: 8:30 - 6:00 <br />SAT AND SUN: 9:00 - 5:00
      </div>
      <div class="menu">
        <a
          class="link-btn"
          rel="noreferrer"
          target="_blank"
          href="./cashel-menu.pdf"
          @click="menuConversion"
        >
          OUR MENU
        </a>
      </div>
      <div class="email">
        <a href="mailto:hello@cashelcoffee.com">HELLO@CASHELCOFFEE.COM</a>
      </div>
      <div class="insta">
        <a
          href="https://www.instagram.com/cashelcoffee/"
          target="_blank"
          rel="noreferrer"
          @click="instaConversion"
        >
          <img
            src="./assets/insta.png"
            class="instaIcon"
            alt="instagram: cashelcoffee"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    menuConversion() {
      window.gtag('event', 'Menu Download', {
        event_category: 'Download',
        event_label: 'Menu',
        value: 1
      });
    },
    instaConversion() {
      window.gtag('event', 'Instagram Jump', {
        event_category: 'click',
        event_label: 'Instagram',
        value: 1
      });
    }
  },
  components: {}
};
</script>

<style>
@font-face {
  font-family: 'DIN Condensed Web';
  src: url('./assets/fonts/69dbc1186412d7831b88d8a78a360360.eot');
  src: url('./assets/fonts/69dbc1186412d7831b88d8a78a360360.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/69dbc1186412d7831b88d8a78a360360.woff2') format('woff2'),
    url('./assets/fonts/69dbc1186412d7831b88d8a78a360360.woff') format('woff'),
    url('./assets/fonts/69dbc1186412d7831b88d8a78a360360.ttf')
      format('truetype');
}

html,
body {
  height: 100%;
}
body {
  font-family: 'DIN Condensed Web', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #7b613c;
  background-color: #1a1a1a;
  margin: 0;
}

#app {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 10px 0px;
}

@media screen and (orientation: portrait) {
  #app {
    padding: 0px;
    align-items: center;
  }
}

@media screen and (orientation: landscape) and (min-height: 768px) {
  #app {
    padding: 0px;
    align-items: center;
  }
}

a {
  color: #7b613c;
}

a.link-btn {
  display: inline-block;
  margin: 10px 0px;
  border: 2px solid #7b613c;
  padding: 10px;
  text-decoration: none;
}

a.link-btn:hover {
  background: #7b613c;
  color: #1a1a1a;
}

@media screen and (min-width: 1181px) {
  .address {
    font-size: 34px !important;
  }

  .email {
    font-size: 18px !important;
    margin-top: 20px;
  }

  .opening {
    font-size: 24px !important;
    margin-top: 20px;
  }

  .menu {
    font-size: 24px !important;
    margin-top: 20px;
  }

  a.link-btn {
    font-size: 24px;
  }
}

@media screen and (max-width: 1180px) {
  .email,
  .opening {
    font-size: 14px !important;
    margin-top: 15px;
  }

  .menu {
    font-size: 18px !important;
    margin-top: 15px;
  }

  a.link-btn {
    font-size: 18px;
  }
}

.cashel {
  width: 90%;
}

.insta {
  text-align: right;
  padding: 15px;
}

@media screen and (max-width: 1180px) {
  img.instaIcon {
    width: 30px;
  }
  .cashel {
    width: 100%;
  }
}
</style>
